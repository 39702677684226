
.booking-template1 {
  width: '96%'; /* or whatever width you want. */
  max-width: 600px; /* or whatever width you want. */
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.booking-template1-success {
  margin-top: 30px;
  margin-bottom: 30px;
}

.booking-template1-success-text {
  color: #357a38;
  text-align: center;
}

.booking-info {
  align-items: center;
  padding-left: 15px;
  justify-content: center;
}

.booking-salon-name {
  font-size: 18px;
  font-weight: bold;
}

.booking-salon-info-text {
  font-size: 16px;
  color: #578688;
  font-weight: 500;
}

.booking-info-address {
  font-size: 16px;
  font-weight: 400;
}

.booking-days {
  display: flex;
  align-items: center;
}

.booking-day-box {
  margin-right: 10px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0px;
  background-color: #fff;
  width: 60px;
}

.booking-day-box-active {
  background-color: #9CC0B7;
  border-radius: 15px;
}

.booking-title {
  font-size: 18px;
  margin-top: 24px;
  color: #858585;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.booking-msg-invalid {
  color: #E12828;
  font-style: italic;
  font-size: 12px;
}

.booking-input {
  height: 44px;
  background: #F3F6F6;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px;
}

.booking-add-booking-button {
  border-width: 0px;
  margin-top: 10px;
  padding: 12px 100px;
  border-radius: 50px;
  align-self: center;
  background-color: #E09672;
}

.booking-add-booking-button:disabled, .booking-add-booking-button[disabled] {
  background-color: #BFBFBF;
}

.booking-login-booking-button {
  background-color: #c9c9c9;
}

.booking-add-booking-captcha {
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
}

.booking-note-view {
  clear: both;
}

.booking-closing-time-text {
  color: #E12828;
  font-style: italic;
}

.image-gallery-slides{
  margin-top: 20px;
  max-width: 600px;
  max-height: 400px;
  width: auto;
  height: auto;
  overflow: hidden;
}

.image-gallery-slide, .image-gallery-image {
  max-height: unset;
  height: 100%;
  object-fit: cover;
}